import PropTypes from "prop-types";
import { memo } from "react";
import { FormattedMessage, FormattedPlural } from "react-intl";
import IconSunFilled from "app/pages/.shared/IconSunFilled";
import IconSunEmpty from "app/pages/.shared/IconSunEmpty";
import IconSunHalf from "app/pages/.shared/IconSunHalf";
import IconRecommendation from "app/pages/.shared/IconRecommendation";

import "./HolidayCheck.scss";

const HolidayCheck = ({
	averageRating = 0,
	countReviews = 0,
	recommendation = 0,
	isCompact = false,
	size = "large",
}) => {
	const averageRatingIntPart = Math.trunc(averageRating);
	const averageRatingFloatPart = Number((averageRating - averageRatingIntPart).toFixed(1));

	const sunFilledIconNumber = averageRatingIntPart;
	const sunHalfIconNumber = averageRatingFloatPart >= 0.5 ? 1 : 0;
	const sunEmptyIconNumber = 6 - sunFilledIconNumber - sunHalfIconNumber;

	const holidayCheckRatingNote = (
		<div className="holidaycheck__rating-note" data-testid="holidaycheck-rating-note">
			<span className="holidaycheck__rating-note-average">{averageRating}</span>
			<span className="holidaycheck__rating-note-total">/ 6</span>
		</div>
	);

	return (
		<div
			className={`holidaycheck ${size} ${isCompact ? "compact" : ""}`}
			data-testid="holidaycheck"
		>
			<div className="holidaycheck__recommendation" data-testid="holidaycheck-recommendation">
				<IconRecommendation />
				{Math.round(recommendation)}%
			</div>
			{isCompact ? (
				<div
					className="holidaycheck__rating-compact"
					data-testid="holidaycheck-rating-compact"
				>
					<IconSunFilled className="holidaycheck__sun" />
					{holidayCheckRatingNote}
				</div>
			) : (
				<div className="holidaycheck__star">
					{sunFilledIconNumber > 0 &&
						Array(sunFilledIconNumber)
							.fill(null)
							.map((_, i) => (
								<IconSunFilled
									className="holidaycheck__sun"
									data-testid="icon-sun-filled"
									key={`icon-sun-filled-${i}`}
								/>
							))}
					{sunHalfIconNumber > 0 &&
						Array(sunHalfIconNumber)
							.fill(null)
							.map((_, i) => (
								<IconSunHalf
									className="holidaycheck__sun icon-sun-half"
									data-testid="icon-sun-half"
									key={`icon-sun-half-${i}`}
								/>
							))}
					{sunEmptyIconNumber > 0 &&
						Array(sunEmptyIconNumber)
							.fill(null)
							.map((_, i) => (
								<IconSunEmpty
									className="holidaycheck__sun"
									data-testid="icon-sun-empty"
									key={`icon-sun-empty-${i}`}
								/>
							))}
					<div className="holidaycheck__rating">{holidayCheckRatingNote}</div>
				</div>
			)}
			{countReviews > 0 && (
				<div className="holidaycheck__reviews" data-testid="holidaycheck-reviews">
					<FormattedPlural
						value={countReviews}
						one={
							<FormattedMessage
								id="product.review.count.singular"
								values={{
									reviewsCount: 1,
								}}
							/>
						}
						other={
							<FormattedMessage
								id="product.review.count.plural"
								values={{
									reviewsCount: countReviews,
								}}
							/>
						}
					/>
				</div>
			)}
		</div>
	);
};

HolidayCheck.propTypes = {
	averageRating: PropTypes.number,
	countReviews: PropTypes.number,
	recommendation: PropTypes.number,
	size: PropTypes.string,
	isCompact: PropTypes.bool,
};

export default memo(HolidayCheck);
